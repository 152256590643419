import { Query }                from '@datorama/akita';
import { Injectable }                     from '@angular/core';
import { RescueOrgState, RescueOrgStore } from './rescue-org.store';

@Injectable({ providedIn: 'root' })
export class RescueOrgQuery extends Query<RescueOrgState> {
  selectName$ = this.select('name');
  selectFullAddress$ = this.select(({ zip, address, city, door }) => `${zip}. ${city}, ${door} ${address}`);
  isInvalidAddress$ = this.select(({ zip, address, city, door }) => !zip || !address || !city || !door);
  selectPhone$ = this.select('phone');
  selectLogoURL$ = this.select('logoURL');
  selectTotalAnimals$ = this.select('totalAnimals');
  selectTotalActiveAnimals$ = this.select('totalActiveAnimals');

  constructor(protected override store: RescueOrgStore) {
    super(store);
  }

  get logoURL() {
    return this.getValue().logoURL;
  }

  get name() {
    return this.getValue().name;
  }

  get fullAddress() {
    const { zip, address, city, door } = this.getValue();
    return `${zip}. ${city}, ${door} ${address}`;
  }

  get phone() {
    return this.getValue().phone;
  }

  get bank() {
    return this.getValue().bank;
  }

  get bankNumber() {
    return this.getValue().bankNumber;
  }

  get tax() {
    return this.getValue().tax;
  }

  get email() {
    return this.getValue().email;
  }

  get website() {
    return this.getValue().website;
  }

  get totalAnimals() {
    return this.getValue().totalAnimals;
  }

  get totalActiveAnimals() {
    return this.getValue().totalActiveAnimals;
  }
}
